import { useState } from "react";

const UserTopbar = ({heading}) => {
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const toggleProfileMenu = (e) => {
        e.preventDefault();
        setShowProfileMenu(!showProfileMenu);
    };
    const handleLogout = () => {
        
        localStorage.removeItem('user');
        window.location.href = '/';
    }
    return (
        <>
            <div className="h-12 py-2 text-center capitalize px-4 bg-blue-950 text-white">
            
                    <span className=" text-center text-2xl text-white">{heading}</span>
                <p className="user_name">{localStorage.getItem("user_name")}</p>
                <div className="float-right relative text-sm group inline-block text-left align-middle">
                    <button type="button" className="group" onClick={toggleProfileMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                    </button>
                    {showProfileMenu && (
                        <div className="absolute right-0 space-y-2 bg-blue-950 border rounded-lg p-4 whitespace-nowrap ...">
                            <ul class="">
                                <li><a href="/user/change-password" class="text-white">Change Password</a></li>
                                <button><a href="#" class="text-white"  onClick={handleLogout}>Logout</a></button>
                            </ul>

                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default UserTopbar;
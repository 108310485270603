import axios from './Axios';
import Cookies from "js-cookie";
const Request = (url, requestPayload = {}, type = "post") => new Promise((resolve, reject) => {
    // const user = JSON.parse(Cookies.get('token'));
    if (type === "get") {
        axios.get(url)
            .then((data) => resolve(data.data))
            .catch((err) => {
                if (err.response?.status === 401) {
                    Cookies.remove('token');
                    // window.location.href = '/admin/login';
                }
                reject(err);
            });
    }
    else {
        axios.post(url, requestPayload)
            .then((data) => resolve(data.data))
            .catch((err) => {
                if (err.response?.status === 401) {
                    Cookies.remove('token');
                    // window.location.href = '/admin/login';
                }
                reject(err);
            });
    }
});
export default Request;

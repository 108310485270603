import { useEffect, useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import { handleApiError } from "../../Helpers/handleApiError ";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import AppUser from "../AppUser";
import { Link } from "react-router-dom";
import Carousel from "../Carousel";
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import animationData1 from '../../animations/loader_animation.json';
import animationData from '../../animations/loader_animation0.json'
const images = ['https://grohelogin.ruminateitsolution.com/4N.jpg', 'https://grohelogin.ruminateitsolution.com/3N.jpg', 'https://grohelogin.ruminateitsolution.com/6N.jpg'];

export default function UserLogin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isStopped, setStopped] = useState(false);
  const [Paused, setPaused] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await UserSection.userLogin({ email, password });
      console.log(response);
      if ((response.status) &&(response.data.data.role_id == 2) && (response.data.data.is_active == 1)) {
        localStorage.setItem("user", response.data.token);
        localStorage.setItem("user_name", response.data.data.name);
        Swal.fire({
          icon: 'success',
          title: 'Login',
          text: 'Wel-come' + "   " + response.data.data.name,
          timer: 30000, // Automatically close after 3 seconds
        });
        window.location.href = '/user';
      } else {
        
        navigate('/login');
      }
      setLoading(false);
    } catch (error) {
      handleApiError(error, 'Failed to fetch data. Please try again later.');

    }
  }
  const handleLogout = () => {
    Cookies.remove('token');
    localStorage.removeItem('user');
    setIsActive(false);
  }
  return (<>
    {loading ? (
      <div className="loder">
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ height: '100px', width: '100px' }}
        />
      </div>
    ) : (
      <div className="max-w-md m-auto bg-gray-950/50 rounded-lg ">

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-12 mt-20" >
          <div className="fixed -z-50 left-0 bottom-0 top-0">
            <Carousel images={images} />
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            {/* <img className="mx-auto h-10 w-auto" src="grohe.png" alt="Your Company" /> */}
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  Email Address
                </label>
                <div className="mt-2">
                  <input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                    Password
                  </label>
                  <div className="text-sm">
                    <a href="/forgotPassword" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    )
    }

  </>
  )
}
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import UserService from '../Services/UserList';
import SidebarNavigation from "./SidebarNavigation";
import MainLayout from './admin/MainLayout';
import { useNavigate } from 'react-router-dom';
export default function EditUserList() {
    const navigate = useNavigate();
    const { id } = useParams();
    // const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        email: '',
        city: '',
        state: '',
        is_active: '',
        emp_code: '',
    });

    useEffect(() => {
        userlistapi();
    }, []);
    const userlistapi = async () => {
        try {
            const response = await UserService.EditUser(id);
            // setUsers(response.data.data);
            setFormData(response.data);
            console.log(formData);
        } catch (error) {
            Swal.fire({
                icon: 'warning',
                title: 'warning',
                text: error,
                timer: 3000, // Automatically close after 3 seconds
            });
        }
    }
    console.log(setFormData);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await UserService.UpdateUser(formData);
            console.log(response);
            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'user',
                    text: 'user update successfully',
                    timer: 3000, // Automatically close after 3 seconds
                });
                navigate('/admin/userlist');
            }
            console.log(formData);

        } catch (error) {
            // console.log(error.errors);
            Swal.fire({
                icon: 'warning',
                title: 'warning',
                text: error.errors,
                timer: 3000, // Automatically close after 3 seconds
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    return (
        <MainLayout>
           
            <form onSubmit={handleSubmit}>

                <div className="space-y-12 w-8/12 m-auto">
                    <div className="pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    First name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="last_name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Last name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        autoComplete="family-name"
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empCode"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Employee Code
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="empCode"
                                        name="empCode"
                                        type="text"
                                        value={formData.emp_code                                        }
                                        onChange={handleChange}
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3 sm:col-start-1">
                                <label
                                    htmlFor="city"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    City
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        autoComplete="address-level2"
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="state"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    State / Province
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="state"
                                        id="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        autoComplete="address-level1"
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="is_active"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Status
                                </label>
                                <div className="mt-2">
                                    <select name="is_active" value={formData.is_active} onChange={handleChange} className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <option value="0">Deactive</option>
                                        <option value="1">Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center gap-x-6">
                    <button type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Save
                    </button>
                    <a href='/admin/home'
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Black
                    </a>
                </div>
            </form>
        </MainLayout>

    );
}

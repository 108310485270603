import React, { useState } from "react";
import FormAddUser from "./FormAddUser";
import AdminUserList from "./AdminUserList";

const Homepage = () => {

  const [activeLink, setActiveLink] = useState('home') // To keep track of the active sidebar link

  const handleSidebarClick = (link) => {
    setActiveLink(link);
  }


  return (
    <div className="flex h-screen">
      {/* Sidebar Navigation */}
      <nav className="w-40 bg-blue-950 p-2">
        <ul className="space-y-4">
          <li className={`${activeLink === 'home' ? 'rounded-lg text-white bg-orange-600' : 'text-blue-400'} p-2 flex `} onClick={() => handleSidebarClick('home')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>

            Home
          </li>
          <li className={`${activeLink === 'addUser' ? 'rounded-lg text-white bg-orange-600' : 'text-blue-400'} p-2 flex `} onClick={() => handleSidebarClick('addUser')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
            </svg>

            Add User
          </li>
          <li className={`${activeLink === 'deactivateUser' ? 'rounded-lg text-white bg-orange-600' : 'text-blue-400'} p-2 flex `} onClick={() => handleSidebarClick('deactivateUser')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
            </svg>

            All User
          </li>
          <li className={`${activeLink === 'helpdesk' ? 'rounded-lg text-white bg-orange-600' : 'text-blue-400'} p-2 flex space-x-4 `} onClick={() => handleSidebarClick('helpdesk')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg>

            Helpdesk
          </li>
        </ul>
      </nav>

      {/* Main Content */}
      <main className="w-11/12 h-screen p-4 overflow-x-auto">


        {/* Content based on the active link */}
        {activeLink === 'home' && <div>Home Content</div>}
        {activeLink === 'addUser' && <div><FormAddUser /></div>}
        {activeLink === 'deactivateUser' && <div className="overflow-x-auto"><AdminUserList /></div>}
        {activeLink === 'helpdesk' && <div>Helpdesk Content</div>}
      </main>
    </div>
  );
};

export default Homepage;

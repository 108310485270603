import React, { useState } from 'react';
import UserSection from "../../Services/User/UserLogin";
const ViewProject = () => {
    const [projectId, setprojectId] = useState();
    const [projectData, setprojectData] = useState();
    const [tableData, setTableData] = useState([]);
    const viewprojectById = async (e) => {
        e.preventDefault();

        try {
            const response = await UserSection.viewProjectById(projectId);
            console.log(response);
            if (response.status) {
                setprojectData(response.data);
                setTableData(response.data);
            }
console.log(tableData);
        } catch (error) {
            console.log(error);
            // handleApiError(error, 'Failed to fetch data. Please try again later.');

        }
    }
    const downloadExcel = async () => {
        try {
            const response = await UserSection.downloadExcel();
            if (response.status) {
                setprojectData(response.data);
            }
        } catch (error) {
            console.log(error);
            // handleApiError(error, 'Failed to fetch data. Please try again later.');

        }
    }
    const [items, setItems] = useState([
        { id: 1, check: false, sku_code: 123, brand: '', brand_description: '', finish: '', image: '', quantity: '', area: '', mrp: '', discount_price: '', discount_per: '', gst: '', total: '', discount: '', selection: '' },

    ]);
    return (
        <>

            <div class="min-w-full mx-auto mt-5">
                <div class="px-6 py-4">
                    <form className="space-y-6" action="#">
                        <div class="mb-4">
                            <label class="block text-gray-700 text-sm font-bold  mb-2" for="clientId">Project ID:</label>
                            <input class="w-1/4 px-3 py-2 text-gray-700 border rounded-md" type="text" id="clientId" name="clientId" placeholder="Enter your username" value={projectId} onChange={e => setprojectId(e.target.value)}></input>
                        </div>
                        <div class="mb-6">
                            <button onClick={viewprojectById} type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Submit
                            </button>
                            <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                Reset
                            </button>
                        </div>

                    </form>
                </div>
                <table class="min-w-full border border-gray-300 divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Sku Code</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Brand</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Brand Description</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Finish</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Quantity</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Area</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Mrp</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Discounted Price</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Discount %</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">GST</th>
                            <th class="border p-2 bg-blue-500 text-white font-semibold">Total</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        
                        {tableData.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan='11' className='text-right'>Total MRP :</td>
                        </tr>
                        <tr>
                            <td colSpan='11' className='text-right'>Total Amount :  </td>
                        </tr>
                        <tr>
                            <td colSpan='11' className='text-right'>Shower Area Subtotal:  </td>
                        </tr>
                        <tr>
                            <td colSpan='11' className='text-right'>Basin Area Subtotal: 0  </td>
                        </tr>
                        <tr>
                            <td colSpan='11' className='text-right'>EWC Area Subtotal: 0  </td>
                        </tr>
                    </tfoot>
                </table>
                <button onClick={downloadExcel} type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2 pr-2">
                    Export To Excel
                </button>
            </div >

        </>
    )
};


export default ViewProject;
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Homepage from "./Homepage1";
import authService from "../Services/auth";
import Swal from 'sweetalert2';
import { handleApiError } from "../Helpers/handleApiError ";
import { handleApiSuccess } from "../Helpers/handleApiSuccess ";
import { useNavigate } from 'react-router-dom';
import Carousel from "./Carousel";
const images = ['https://grohelogin.ruminateitsolution.com/3N.jpg', 'https://grohelogin.ruminateitsolution.com/6N.jpg', 'https://grohelogin.ruminateitsolution.com/4N.jpg'];


export default function Login() {
  const navigate = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActive, setIsActive] = useState(false);

  // check for a token when component mounts
  // useEffect(() => {
  //   const token = Cookies.get('token');
  //   if (token) {
  //     setIsActive(true);
  //   }
  // }, [])

  const handleLogin = async (e) => {

    e.preventDefault();
    try {
      const response = await authService.authlogin({ email, password });
      console.log(response);
      if ((response.status) && (response.data.data.role_id == 1) && response.data.data.is_active == 1) {
        handleApiSuccess(response.message, 'Success');
        localStorage.setItem("user", response.data.token);
        console.log(localStorage.getItem("user"));
        // navigate('/admin/home');
        window.location.href = '/admin/home';
      } else {

        alert("We don't assign any permission");
      }
      console.log(response);
    } catch (error) {
      handleApiError(error, 'Failed to fetch data. Please try again later.');
      console.error(error);
    }
  }
  // Add a logout function to remove the token
  const handleLogout = () => {
    Cookies.remove('token');
    setIsActive(false);
  }
  return (
    <>
      <div className="max-w-md m-auto bg-gray-950/50 rounded-lg ">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-20">
          <div className="fixed -z-50 left-0 bottom-0 top-0">
            <Carousel images={images} />

          </div>

          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            {/* <img className="mx-auto h-10 w-auto" src="/grohe.png" alt="Your Company" /> */}
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Sign in to your account
            </h2>
          </div>

          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-white">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 text-white">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>

    </>
  )
}

import { useState, useEffect } from "react";
import UserTopbar from "../UserTopbar";
import UserSection from "../../Services/User/UserLogin";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import { handleApiError } from "../../Helpers/handleApiError ";
import Lottie from 'lottie-react';
import animationData from '../../animations/loader_animation0.json';
const UserHelpDesk = () => {
  const navigate = useNavigate();

  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    categoery: 'login_issue',
    text: '',
  });
  const myStyles = {
    position: 'absolute',
    marginTop: '-58px',
    marginLeft: '700px',
    border: '1px solid',
    padding: '10px',
    width:'300px',
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await UserSection.saveSuggestion(formData);
      if (response.status) {
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: 'Ticket ress  successfully',
          timer: 3000, // Automatically close after 3 seconds
        });
        setFormData({ categoery: 'login_issue', text: '' });
        document.getElementById('categoery').value = '';
        setLoading(false);
        navigate("/user/help-desk");
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
      handleApiError(error, 'Failed to fetch data. Please try again later.');
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };
  const helpDeskList = async () => {
    try {
      setLoading(true);
      const response = await UserSection.helpDeskList();
      setData(response.data)
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      handleApiError(error, 'Failed to fetch data. Please try again later.');
    }
  }
  useEffect(() => {
    helpDeskList();
  }, [data]);
  return (
    <>
      <UserTopbar heading={`Help-Desk`} />
      <div className="mt-10 sm:mx-auto sm:w-full max-w-8/16">
        <form className="space-y-10 p-4 suggestion" action="#" >
          <div className="w-ful">
            <div className="w-1/2">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
                Category
              </label>
              <select id="categoery" name="categoery" onChange={handleChange} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1  placeholder:text-gray-400    sm:text-sm sm:leading-6">
                <option value="" selected>Select Category </option>
                <option value="login_issue" >Login issue</option>
                <option value="change_password">Change password</option>
                <option value="Quotation_related_issues">Quotation related issues</option>
                <option value="view_project_related_issue">View project related issue</option>
                <option value="summary_related_issue">Summary related issue</option>
                <option value="suggestions">Suggestions</option>
                <option value="Others">Others</option>
              </select>

            </div>
            <div className="w-1/2" style={myStyles}>
              <label>Phone Number : 9625650376 </label>
              <br/>
              <label>Email : helpdesk.ruminate@gmail.com</label>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="text" className="block text-sm font-medium leading-6 text-black">
                Message
              </label>

            </div>
            <div className="mt-2">
              <textarea
                rows="4"
                cols="150"
                value={formData.text}
                onChange={handleChange}
                id="text"
                name="text"
                type="text"
                autoComplete="suggestiion"
                required
              />
            </div>
          </div>

          <div>
            <button
              onClick={handleSubmit}
              type="button"
              className="bg-blue-950 text-white p-2 rounded w-32"
            >
              Send
            </button>
          </div>
        </form>
      </div>

      {data ? (
        <table class="min-w-full border border-gray-300 divide-y divide-gray-300 productTable p-2">
          <thead>
            <tr>
              <th class="border bg-blue-950 text-white p-2 font-semibold">Ticket ID</th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">Categoery</th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">Message</th>
              <th class="border bg-blue-950 text-white p-2 font-semibold">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr className="text-center">
                <td>{item.id}</td>
                <td>{item.categoery}</td>
                <td>{item.suggestion}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div class="loder">
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: '100px', width: '100px' }}
          />
        </div>
      )

      }
    </>
  );
}
export default UserHelpDesk;
import axios from 'axios';
import Cookies from "js-cookie";
// const user = Cookies.get('token');
const user = localStorage.getItem("user");
// const instance = axios.create({ baseURL: 'https://testingruminate.co.in/public/api/'});
const instance = axios.create({ baseURL: 'https://quatationapis.groheapp.in/api/'});
instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common['Accept'] = '*/*';
if(user) {
    instance.defaults.headers.common['Authorization'] = 'Bearer '+ user;
} 
export default instance;
import axios from "../Helpers/Axios";
import Request from "../Helpers/Request";
import Cookies from "js-cookie";
const authlogin = async (data) => {
    return await Request('admin/login', data);
};
const authService = {
    
    authlogin,
}
export default authService;
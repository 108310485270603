import MainLayout from "./MainLayout";

const Homepage = () => {
   
return(
    <>
   <MainLayout/>
   wel-come
    </>
)
}
export default Homepage;
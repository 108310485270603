import { NavLink, Outlet, } from "react-router-dom";
import { checkLogin } from "../../Helpers/checkLogin";
import { useState, useEffect } from "react";
import UserService from "../../Services/UserList";
import { handleApiError } from "../../Helpers/handleApiError ";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
const UserLayout = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('/user')
    const onSidebarClick = (link) => {
        setActiveLink(link);
    }

    useEffect(() => {
        setActiveLink(location.pathname);

    }, []);
    return (
        <>
            {
                <div className="flex">
                    <nav className="px-3 w-1/6 h-screen bg-blue-950 text-white h-full user_silider">
                        <ul className="space-y-4 pt-12 text-left text-base">
                        
                            <li>
                                <Link to="/user" className={`${activeLink == '/user' ? 'flex gap-1 block p-2 rounded-xl active' : 'flex gap-1 block p-2 rounded-xl'} py-4`} onClick={() => onSidebarClick('/user')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                    </svg>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/projects" className={`${activeLink === '/user/projects' ? 'flex gap-1 block p-2 rounded-xl active' : 'flex gap-1 block p-2 rounded-xl'} py-4`} onClick={() => onSidebarClick('/user/projects')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                    </svg>
                                    Projects
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/summary" className={`${activeLink === '/user/summary' ? 'flex gap-1 block p-2 rounded-xl active' : 'flex gap-1 block p-2 rounded-xl'} py-4`} onClick={() => onSidebarClick('/user/summary')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                    </svg>
                                    Summary
                                </Link>
                            </li>
                            <li>
                                <Link to="/user/viewProjects" className={`${activeLink === '/user/viewProjects' ? 'flex gap-1 block p-2 rounded-xl active' : 'flex gap-1 block p-2 rounded-xl'} py-4`} onClick={() => onSidebarClick('/user/viewProjects')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                                    </svg>
                                    View Project
                                </Link>
                            </li>
                            <Link to='/user/help-desk' className={`${activeLink === '/user/help-desk' ? 'flex gap-1 block p-2 rounded-xl active' : 'flex gap-1 block p-2 rounded-xl'} py-4`}  onClick={() => onSidebarClick('/user/help-desk')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>

                                Helpdesk
                            </Link>
                        </ul>
                    </nav>



                    <div className="w-full">

                        <Outlet />
                    </div>

                </div>



            }
        </>
    )
}

export default UserLayout;
import UserTopbar from "../UserTopbar";
import Products from "./Products";
import Summary from "./Summary";
import UserProducts from "./UserProjects";
import ViewProject from "./ViewProject";
import ViewProjects from "./ViewProjects";

const UserHomepage = ({ activeLink }) => {

    return (
        <main className="w-full">
            <UserTopbar heading="Homepage"/>
            <img src="https://grohelogin.ruminateitsolution.com/2N.jpg" alt="homepage" width={100} className="w-full h-full"/>
            
        </main>
    )
};

export default UserHomepage;
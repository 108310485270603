import { useEffect, useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import { Link } from "react-router-dom";
import UserTopbar from "../UserTopbar";
import animationData from '../../animations/loader_animation0.json';
import Lottie from 'lottie-react';
// import { handleApiError } from "../../Helpers/handleApiError ";
const Summary = () => {
    useEffect(() => {
        projectData();
    }, []);
    const [project, setProject] = useState([
        { user_id: '', client_id: '', finish: '', area: '', total: '', project_id: '' },

    ]);
    const [loading, setLoading] = useState(false);
    const projectData = async () => {
        try {
            setLoading(true);
            const response = await UserSection.projectData();
            if (response.status) {
                console.log(response);
                setProject(response.data)
                console.log(project);
            }
            setLoading(false);
        } catch (error) {
            // handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
    return (
        <>
         <UserTopbar heading={`Project Summary`} />
            {loading ? (
                <div class="loder">
                <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                    style={{ height: '100px', width: '100px' }}
                />
                </div>
            ) : (
               
           
            <table class="min-w-full border border-gray-300 divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th class="border bg-blue-950 text-white p-2 font-semibold">Project ID</th>
                        <th class="border bg-blue-950 text-white p-2 font-semibold">Client ID</th>
                        <th class="border bg-blue-950 text-white p-2 font-semibold">Client Name</th>
                        <th class="border bg-blue-950 text-white p-2 font-semibold">Date</th>
                        {/* <th class="border bg-blue-950 text-white p-2 font-semibold">Total</th> */}
                        {/* <th class="border p-2 bg-blue-500 text-white font-semibold">Action</th> */}
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-300">
                    {project.map((row) => (
                        <tr key={row.id}>
                            <td className="border p-2 text-center">{row.project_id}</td>
                            <td className="border p-2 text-center">{row.client_id}</td>
                            <td className="border p-2 text-center">{row.client_name}</td>
                            <td className="border p-2 text-center">{new Date(row.created_at).toLocaleDateString()}</td>
                            {/* <td className="border p-2 text-center">{row.sub_total}</td> */}
                            {/* <Link to={`/user/project/edit/${row.id}`}  ="text-blue-500 font-semibold hover:underline border p-4 text-center">Edit </Link> */}
                        </tr>
                    ))}

                </tbody>
            </table>
 
            )}
        </>
    )
};


export default Summary;
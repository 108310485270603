import React, { useState, useEffect } from 'react';
import SidebarNavigation from '../SidebarNavigation';
import { handleApiError } from "../../Helpers/handleApiError ";
import UserSection from "../../Services/User/UserLogin";
import Swal from 'sweetalert2';
import UserTopbar from '../UserTopbar';
const Products = () => {
    useEffect(() => {
        ClientList();
        Selection();

    }, []);
    const [selectionId, setSelectionID] = useState('1');
    const [tableData, setTableData] = useState([
        { id: 1, check: false, sku_code: '', brand: '', brand_description: '', finish: '', image: '', quantity: '', area: '', mrp: '', discount_price: '', discount_per: '', gst: '', total: '', discount: '', selection: selectionId, active: true },
    ]);
    const [clientId, setclientId] = useState();
    const [data, setData] = useState('');
    const [client, setClient] = useState();
    const [selection, setSelection] = useState();
    const [selectionName, setSelectionName] = useState();
    const [qty, setQty] = useState();
    const [amountType, setAmountType] = useState('price');
    const [discountPer, setDiscountPer] = useState();
    const [sub_Total, setSub_Total] = useState(0);
    const [formData, setFormData] = useState({
        client_name: '',
        site_add: '',
        afd: '',
        add_to: '',
        username: '',
    });
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [items, setItems] = useState([
        { id: 1, check: false, sku_code: '', brand: '', brand_description: '', finish: '', image: '', quantity: '', area: '', mrp: '', discount_price: '', discount_per: '', gst: '', total: '', discount: '', selection: '' },

    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };
    //  Add Client
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await UserSection.addClient(formData);
            if (response.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'client',
                    text: 'Client create  successfully',
                    timer: 3000, // Automatically close after 3 seconds
                });
            }
        } catch (error) {
            console.log(error);
            // handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
    // Login with id

    const viewclent = async (e) => {
        e.preventDefault()
        try {
            const response = await UserSection.clientLogin(clientId);
            if (response.status) {
                setData(response.data)
                Swal.fire({
                    icon: 'success',
                    title: 'Client',
                    text: 'Client fetch  successfully',
                    timer: 3000, // Automatically close after 3 seconds
                });
            }
        } catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
    const ClientList = async () => {

        // e.preventDefault()
        try {
            const response = await UserSection.allClients();
            // console.log(response.data);
            if (response.status) {
                setClient(response.data);
            }

        } catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }
    const handleSelectChange = async (e) => {
        setclientId(e.target.value);
    }
    const handleKeyUp = async (e, id) => {
        // setSku_code(e.target.value);
        try {
            const response = await UserSection.productBySkeu(e.target.value);
            if (response.status) {
                // Create a copy of the array
                const updatedItems = [...tableData];

                // Find the index of the object with the matching id
                const index = updatedItems.findIndex(item => item.id === id);
                // Update the object's properties
                if (index !== -1) {
                    const resdata = { id: updatedItems[index].id, check: false, sku_code: response.data.sku_code, brand: response.data.brand, product_desc: response.data.product_desc, finish: response.data.finish, image: response.data.img, quantity: response.data.qty, area: response.data.area, mrp: response.data.mrp, discount_price: '', discount_per: '', gst: response.data.gst, total: 0, discount: 0, selection: selectionId, active: true };
                    // updatedItems = { ...updatedItems[index], resdata };
                    updatedItems[index] = resdata;
                    setTableData(updatedItems); // Update the state with the modified array
                }
                // setItems(response.data);
                console.log(tableData);
            }
        } catch (error) {
            console.log(error);
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
        // console.log(skeu_code);
    }

    const Selection = async () => {
        try {
            const response = await UserSection.allselection();
            if (response.status) {
                setSelection(response.data);
            }
        } catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }

    const saveProjectData = async (e) => {
        e.preventDefault();
        const mergedState = {
            tableData, // Spread the properties of state1
            clientId, // Spread the properties of state2
            selectionId,
            selectionName,
        };
        try {
            const response = await UserSection.saveProductData(mergedState);
            console.log(response);
            if(response.status){
                alert('Project added succesfully');
            }
            
        } catch (error) {
            handleApiError(error, 'Failed to fetch data. Please try again later.');
        }
    }


    const addRow = (e) => {
        e.preventDefault();
        var total_slot = 0;
        for (let i = 0; i < tableData.length; i++) {
            if(tableData[i].selection == selectionId)
            {
                total_slot+=1;
               
            }
        }
        // console.log(total_slot);
        if(total_slot >=10)
        {
            alert("You were cross selection limits ");
            return false;
        }
        var row_id = tableData.length + 1;
        const newRow = { id: row_id, check: false, sku_code: '', brand: '', brand_description: '', finish: '', image: '', quantity: '', area: '', mrp: '', discound_price: '', discound_per: '', gst: '', selection: selectionId, active: true };
        setTableData([...tableData, newRow]);
        
    };

    const removeRow = (e, id) => {
        e.preventDefault();
        if (tableData.length <= 1) {
            return; // No rows to remove
        }
        const updatedItems = [...tableData];
        // console.log(updatedItems);
        for (let i = 0; i < updatedItems.length; i++) {
            if (tableData.length <= 1) {
                return; // No rows to remove
            }
            if (updatedItems[i].check === true) {

                const updatedItems1 = updatedItems.filter((item, index) => index !== i);
                setTableData(updatedItems1);
            }

        }

    }
    const resetState = () => {
        setTableData({ id: 1, check: false, sku_code: '', brand: '', brand_description: '', finish: '', image: '', quantity: '', area: '', mrp: '', discount_price: '', discount_per: '', gst: '', total: '', });
    };
    const handleCheckboxChange = (status, index) => {
        const updatedItems = [...tableData];
        // const index = updatedItems.findIndex(item => item.id === id);

        updatedItems[index].check = updatedItems[index].check ? false : true;
        setTableData(updatedItems);
        // selectedCheckbox([...setSelectedCheckbox, options]);
        console.log(tableData)
    }
    const changeformdataqty = (e, index) => {
        const updatedItems = [...tableData];
        const all_total = sub_Total;
        // const index = updatedItems.findIndex(item => item.id === id);
        updatedItems[index].quantity = e.target.value;
        const roundTotal = updatedItems[index].mrp * updatedItems[index].quantity * updatedItems[index].gst / 100;
        updatedItems[index].total = updatedItems[index].mrp * updatedItems[index].quantity + roundTotal - updatedItems[index].discount;
        // all_total +  updatedItems[index].total;
        updatedItems[index].total = updatedItems[index].total - updatedItems[index].discount;
        setSub_Total(all_total + updatedItems[index].total);
        setTableData(updatedItems);
    }
    const changeAmountType = (e, index) => {
        let updatedItems = [...tableData];
        if (amountType == 'price') {
            updatedItems[index].discount_price = e.target.value;
            updatedItems[index].discount = updatedItems[index].total - e.target.value;
            updatedItems[index].total = updatedItems[index].total - e.target.value;
            updatedItems[index].sub_Total = updatedItems[index].total;
            updatedItems[index].discount_per = e.target.value /100;
            // updatedItems[index].discount_price = e.target.value;

        } else {
            updatedItems[index].discount_per = e.target.value;
            updatedItems[index].discount = updatedItems[index].total * e.target.value / 100;
            updatedItems[index].total = updatedItems[index].total - updatedItems[index].discount;
            updatedItems[index].sub_Total = updatedItems[index].total;
            updatedItems[index].discount_price = e.target.value * 100;
            // updatedItems[index].discount_per = e.target.value;

        }
        // }

        setTableData(updatedItems);
        console.log(tableData);
    }
    const setArea = (e, index) => {
        alert(e);
        const updatedItems = [...tableData];
        updatedItems[index].area = e;
    }
    const changeSelection = (e) => {
        setSelectionID(e.target.value);
        let updatedItems = [...tableData];
        
        var total_slots = 0;
        setSelectionID(e.target.value);
        for (let i = 0; i < updatedItems.length; i++) {
            if (updatedItems[i].brand == '') {
                total_slots += 1;
            }
            if (updatedItems[i].brand != '' && updatedItems[i].selection != e.target.value) {
                updatedItems[i].active = false;
            } else {
                updatedItems[i].active = true;
            }
        }
        setTableData(updatedItems);

        if (total_slots <= 0) {
            addRow(e);
        }

    }
    const place = selectionId ? selectionId : 1;
    const concatenatedString = `Enter selection name ${place}`;
    return (
        <> <div className='flex'>
            <div>
                <div class="grid grid-cols-2 gap-4 w-screen">
                    <div class="p-1 add-client">
                        <div class="px-6 py-1">
                            <div class="font-bold text-xl mb-2">Add Client Details</div>
                        </div>
                        <div class="px-6 py-1">
                            <form className="space-y-6" action="#" method="POST">
                                <div class="mb-2 flex justify-evenly">
                                    <label class="block text-gray-700 text-sm  mb-2" for="client_name">Client Name</label>
                                    <input class="w-8/12 px-3 py-1 text-gray-700 border rounded-md" type="text" id="client_name" name="client_name" placeholder="Enter your username" value={formData.client_name} onChange={handleChange} ></input>
                                </div>
                                <div class="mb-2 flex justify-evenly">
                                    <label class="block text-gray-700 text-sm  mb-2" for="sitaddress">Site Address</label>
                                    <input class="w-8/12 px-3 py-1 text-gray-700 border rounded-md" type="text" id="site_add" name="site_add" placeholder="Enter your username" value={formData.site_add} onChange={handleChange}></input>
                                </div>
                                <div class="mb-2 flex justify-evenly">
                                    <label class="block text-gray-700 text-sm  mb-2" for="details">Architect Firm <br></br>Details</label>
                                    <input class="w-8/12 px-3 py-1 text-gray-700 border rounded-md h-fit" type="text" id="afd" name="afd" placeholder="Enter your username" value={formData.afd} onChange={handleChange}></input>
                                </div>
                                <div class="mb-2 flex justify-evenly">
                                    <label class="block text-gray-700 text-sm  mb-2" for="add_to">Addressed To</label>
                                    <input class="w-8/12 px-3 py-1 text-gray-700 border rounded-md" type="text" id="add_to" name="add_to" placeholder="Enter your username" value={formData.add_to} onChange={handleChange} ></input>
                                </div>
                                <div class="mb-2 flex justify-evenly">
                                    <label class="block text-gray-700 text-sm  mb-2" for="username">username</label>
                                    <input class="w-8/12 px-3 py-1 text-gray-700 border rounded-md" type="text" id="username" name="username" placeholder="Enter your username" value={formData.username} onChange={handleChange} ></input>
                                </div>
                                <button onClick={handleSubmit} class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2 rounded" >
                                    Add New Client
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="p-4">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">View Client Details</div>
                        </div>
                        <div class="px-6 py-4">
                            <form className="space-y-6" action="#" method="POST">
                                {/* <div class="mb-4">
                                    <label class="block text-gray-700 text-sm font-bold  mb-2" for="clientId">Client ID:</label>
                                    <input class="w-full px-3 py-2 text-gray-700 border rounded-md" type="text" id="clientId" name="clientId" placeholder="Enter your username" value={clientId} onChange={e => setclientId(e.target.value)}></input>
                                </div> */}
                                <div class="mb-4">
                                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">Client Name:</label>
                                    {
                                        <select class='w-full block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  border border-black rounded-lg' onChange={handleSelectChange}>
                                            <option value="option1">Select User</option>
                                            {client && (client.map((item) => (
                                                <option value={item.id} onChange={handleSelectChange}> {item.client_name}  {item.id}</option>
                                            )))}
                                        </select>
                                    }
                                </div>
                                <button onClick={viewclent} class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2 rounded" >
                                    Submit
                                </button>
                                <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2 rounded" >
                                    Reset
                                </button>
                            </form>
                        </div>
                        <div class="client-details">
                            <table className="w-1/2 client-details">
                                {
                                    data && (
                                        <tbody>
                                            <tr>
                                                <td>Id</td>
                                                <td>{data.id}</td>
                                            </tr>
                                            <tr>
                                                <td>Clent Name</td>
                                                <td>{data.client_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Site Address:</td>
                                                <td>{data.site_add}</td>
                                            </tr>
                                            <tr>
                                                <td>Addressed To:</td>
                                                <td>{data.add_to}</td>

                                            </tr>
                                            <tr>
                                                <td>Architect Firm Details:	</td>
                                                <td>{data.afd}</td>
                                            </tr>


                                        </tbody>

                                    )
                                }

                            </table>
                        </div>
                    </div>
                </div>
                <div className='flex my-3 justify-between'>
                    <select className="border border-gray-300 p-2 rounded-md  focus:outline-none focus:border-primary w-1/4" onChange={(e) => changeSelection(e)} required>
                        <option value={selectionId}>{`selection${selectionId}`} </option>
                        {selection && (selection.map((item) => (
                            <option value={item.id} > {item.name} </option>
                        )))}
                    </select>
                    <input type='text' className="border border-gray-300 p-2 rounded-md  focus:outline-none focus:border-primary w-1/4" placeholder={concatenatedString} value={(e) => setSelectionName(e.target.value)}></input>
                    <input type='text' className="border border-gray-300 p-2 rounded-md  focus:outline-none focus:border-primary w-1/6" placeholder='Enter client Id' value={clientId}></input>
                    <div className='w-1/4'>
                        <label><input type="radio" className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary" name='discount' value="price" onChange={e => setAmountType('price')} checked={amountType === 'price' ? true : false}></input>Discount Price</label>
                        <label><input type="radio" className="form-checkbox  w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary" name='discount' value="per" onChange={e => setAmountType('percentage')} checked={amountType === 'percentage' ? true : false}></input>Discount % </label>
                    </div>
                </div>
                <div className="w-screen">
                    <form >
                        <table className="">
                            <thead>
                                <tr>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">+/-</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Sku Code</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Brand</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Brand Description</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Finish</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Product Image	</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Quantity</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Area</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">MRP</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Discounted Price</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Discount %	</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">GST</th>
                                    <th className="border p-2 bg-blue-500 text-white font-semibold">Total</th>
                                </tr>
                            </thead>
                            <tbody>

                                {tableData.map(function(row, index) {
                                    console.log(row)
                                    if(row.active) {
                                        return <tr key={row.id}>
                                            <td className='border-b border-gray-300 p-2'><input type='checkbox' class='orm-checkbox h-5 w-5 text-primary border-2 border-gray-300 rounded-md focus:ring-primary' onChange={e => handleCheckboxChange(e.target.value, index)}></input></td>
                                            <td><input class="px-3 py-1 text-gray-700 border rounded-md" type="text" id="" name="sku_code" onChange={(e) => handleKeyUp(e, row.id)} size='4' value={row.sku_code}></input></td>
                                            <td>{row.brand}</td>
                                            <td>{row.product_desc}</td>
                                            <td>{row.finish}</td>
                                            {/* <td>{row.image}</td> */}
                                            <td> <img src={row.image} /></td>
                                            <td><input className="px-3 py-1 text-gray-700 border rounded-md" type="text" id="" name="qty" value={row.quantity} onChange={e => changeformdataqty(e, index)} size='3'></input></td>
                                            <td><input type='radio' name={'area'+[row.id]} onChange={() => setArea('shower', index)} value={row.area} checked={row.area ==='shower'}></input>Shower <br /> 
                                             <input type='radio' name={'area'+[row.id]} onChange={() => setArea('basin', index)} value='basin' checked={row.area ==='basin'}></input>Basin<br /> 
                                              <input type='radio' name={'area'+[row.id]} onChange={(e) => setArea('ewc', index)} value='ewc' checked={row.area ==='ewc'}></input> EWC </td>
                                            <td>{row.mrp}</td>
                                            <td><input className="px-3 py-1 text-gray-700 border rounded-md" type="text" id="" name="" disabled={amountType === 'price' ? false : true} onChange={e => changeAmountType(e, index)} size='3' value={row.discount_price}></input></td>
                                            <td><input className="px-3 py-1 text-gray-700 border rounded-md" type="text" id="" name="" disabled={amountType == 'percentage' ? false : true} onChange={e => changeAmountType(e, index)} value={row.discount_per}></input></td>
                                            <td>{row.gst}</td>
                                            <td>{row.total}</td>
                                            <td>{row.selection}</td>
                                        </tr>
                                    }
                                    

                                })}

                                <tr><td colSpan="12">
                                    Total Amount
                                </td>
                                    <td>{sub_Total}</td>
                                </tr>
                            </tbody>
                            <tfoot>

                            <div>
                                <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2 rounded" onClick={addRow}>
                                    Add Row
                                </button>
                                <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-1 mr-2 rounded" onClick={(e) => removeRow(e)}>
                                    Remove
                                </button>
                                <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-1 mr-2 rounded" onClick={() => resetState()}>
                                    Reset                        </button>
                                <button onClick={saveProjectData} class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-1 mr-2 rounded" >
                                    Save
                                </button>
                            </div>
                        </tfoot>

                        </table>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
};


export default Products;